import React from 'react';

const ImpactTheCostFAQ = () => ([
    {
      title: 'When do you need to remortgage?',
      description: (
        <>
          The main reason why you would remortgage is when your current mortgage deal comes to an end. If you don't remortgage in time, the lender is likely to move you to their standard variable rate (SVR) which is much more expensive than the cost of your original mortgage deal. <br /><br />
          You may also need to remortgage when you move home or want to release cash for home improvements or move home.
        </>
      ),
    },
    {
      title: 'Should I take out a longer or shorter mortgage term?',
      description: (
        <>
          The length of your mortgage depends on your financial circumstances and preferences. <br/><br/>
          If you’re looking to keep your monthly payments to a minimum then a longer-term mortgage would be more suitable. Although this makes the mortgage more expensive overall.<br /><br />
          On the other hand, if you’re looking to pay off your mortgage as quickly as possible and are less restricted by monthly budgets, then a shorter-term mortgage with higher monthly payments may be the better option for you.<br /><br />
          It’s a good idea to speak to a mortgage broker to weigh up the pros and cons to ensure you’re making the right decision for you.
        </>
      ),
    },
    {
      title: 'What is a fixed-rate mortgage? What are the pros and cons?',
      description: (
        <>
          A fixed-rate mortgage is a mortgage where the monthly repayments stay the same during the whole term of the mortgage deal.<br/><br/>
          Fixed-rate mortgages are a good idea if you want the security of knowing what your monthly repayments are going to be, so you can plan and budget your finances.<br /><br />
          The disadvantage of a fixed-rate mortgage is that if interest rates fall you won't benefit from lower mortgage rates as your interest on your mortgage deal will stay the same.
        </>
      ),
    },
    {
      title: 'What is a variable rate mortgage? What are the pros and cons?',
      description: (
        <>
          Unlike fixed-rate mortgages, a variable-rate mortgage is where your mortgage deal interest rate and monthly repayments can increase or decrease during the term of your mortgage.<br/><br/>
          There are a few types of variable rate mortgages, including a tracker mortgage which follows the Bank of England base rate, and a standard variable mortgage which is set out by the lender and tends to be more expensive.<br /><br />
          The pros of a variable mortgage are that if interest rates fall then you'll benefit from your mortgage rates lowering and your mortgage monthly payments being lower.
        </>
      ),
    },
  ]);

export default ImpactTheCostFAQ;
